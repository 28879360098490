import React from "react";
import './index.less'
import introduce from '../assets/introduce.png'
import phone from '../assets/phone.png'
import money from '../assets/money.png'
import edit from '../assets/edit.png'
import submit from '../assets/submit.png'
import logo from '../assets/union_logo.png'
function Team() {
    const comData = [
        {
            title: '更多选择',
            note: '更多平台选择加入',
            image: submit
        },
        {
            title: '更多订单',
            note: '合作平台海量订单',
            image: money
        },
        {
            title: '更多便捷',
            note: '更便捷操作接单',
            image: edit
        }
    ]
    return <div className="product-banner clearfix">
        <div className="content">
            <div className="introduce">
                <div className="introduce-title"><img className="product-logo" src={logo}/><span className="title-desc">单来乐</span></div>
                <li className="introduce-desc">合作平台包括网约车主流大平台，选择多有保障</li>
                <li className="introduce-desc">合作平台订单多，订单类型丰富，订单优质，抢单自由</li>
                <li className="introduce-desc">功能简单易用，界面清晰，操作便捷</li>
                <div className="introduce-img">
            {comData.map(obj=><div  className="introduce-item">
            <h2 className="title" >{obj.title}</h2>
            <div className="note">{obj.note}</div>
            <div className="image"> <img src={obj.image} alt="" /></div>
                    </div>)}
            </div>
            </div>
        </div>
        <div className="product-phone">
            <img src={phone} style={{ height: "100%", width: 'auto' }} alt="" />
        </div>
    </div>
}
export default Team;