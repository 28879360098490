import React from "react";
import './index.less'
function Home() {

    return <div className="home-banner">
        <div className="content">
            <h1 className="title">智慧出行解决方案提供商</h1>
            <div className="item">不断超越，为企业提供综合智慧出行解决方案</div>
            
        </div>
    </div>
}
export default Home;
