import './App.less';
import { Layout } from 'antd';
import React, { useState, useEffect } from 'react';
import Home from '../components/home.jsx';
import Team from '../components/team.jsx';
import Product from '../components/product.jsx';
const { Header, Content } = Layout;
function App() {
  const [type, setType] = useState('home');

  return (
    <>
      <Layout >
        <Content >
          <div className='menu'>
            <div className='menu-title'>
              融浩峰科技
            </div>
            <div className='menu-navigate'>
              <div className='menu-navigate-item' onClick={() => setType('home')}>
                首页
              </div>
              <div className='menu-navigate-item' onClick={() => setType('team')}>
                关于我们
              </div>
              <div className='menu-navigate-item' onClick={() => setType('product')}>
                产品介绍
              </div>
            </div>
          </div>
          {type === 'home' && 
          <div className='home-banner'>
            <Home/> 
          </div>
          }
          {type === 'team' && 
          <Team/>
          }
          {type === 'product' && <Product/>}
          <div className='company-text'>
            <span className='footer-text'>
             上海融浩峰科技有限公司
             </span>
             <span className='footer-text-link' onClick={() => window.open('https://beian.miit.gov.cn')}>
             沪ICP备2024047526号
             </span>
             <span className='footer-text'>
             上海市闵行区合川路3136号7栋西侧315室
             </span>
             <span className='footer-text'>
             联系方式：business@ronghaofengkeji.com
             </span>
          </div>
          </Content>
      </Layout>

    </>
  );
}

export default App;
