import React from "react";
import './index.less'
import team from '../assets/team.png'
function Team() {
    const comData = [
        {
            num: 2023,
            unit: '年',
            note: '公司成立'
        },
        {
            num: 500,
            unit: '万',
            note: '注册资本'
        },
        {
            num: '数十',
            unit: '个',
            note: '成功方案'
        }
    ]
    return <div className="team-banner">
        <div className="content">
            <div class="vertical-line"></div>
            <div className="title">
                <div className="title-chinese" >公司简介</div>
                <div className="title-english">COMPANY PROFILE</div>
            </div>
            <div className="text">
                融浩峰科技，致力于帮助企业更简单轻松的从事出行行业，帮助企业定制全套产品研发解决方案，让企业能更快速上手，为行业创造价值。
            </div>
            <div className="data">
                {
                    comData.map(item => <div div className="company-data">
                        <div class="vertical-line"></div>
                        <div className="data-text">
                        <span className="num">{item.num} </span>
                        <span className="unit">{item.unit}</span>  
                        <div className="note">{item.note}</div>
                        </div>

                    </div>)
                }

            </div>
        </div>
        {/* <img src={team} alt="" /> */}
    </div>
}
export default Team;
